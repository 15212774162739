import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Formik, FormikProps } from 'formik'
import { Box } from '@chakra-ui/react'
import {
  FormRow,
  MultiSelectField,
  SelectField,
  SwitchField,
  TextField,
} from 'src/ui/formik/styled-fields'
import { Button, ConfigCard, Placeholders } from 'src/ui'
import { getEnvironment } from 'src/utils'
import { hasPermission } from 'src/utils/permissions'
import { LocationDetailProvider, useLocationDetailContext } from './location-details-context'
import { usePositions } from 'src/api/queries/positions'
import {
  useCreateOrUpdateLocation,
  useGetLocationById,
} from 'src/api/queries/microservices/aggregator'
import { useToast } from 'src/utils/toast'
import * as Yup from 'yup'
import { LocationPositions } from 'src/companies/locations/location-positions'
import FacilityOptions from './facility-management/facility-options'
import { LocationType } from './utils'
import { CreateOrUpdateLocationRequest } from 'src/api/microservices/aggregator'
import { useCompanyContext } from 'src/companies/routes/company-context'

export default function LocationDetailsPage() {
  const { company } = useCompanyContext()
  const toast = useToast()
  const { id: locationId } = useParams()
  const { data, isFetching, isError, error } = useGetLocationById({
    tenantId: company.tenant_id,
    locationId,
    companyId: company.id,
  })

  if (locationId && isFetching) {
    return <Placeholders.LoadingState />
  }

  if (isError) {
    toast({
      status: 'error',
      title: 'Failed to load location',
      description: error?.response?.data?.message,
    })
  }

  return (
    <LocationDetailProvider
      locationData={data?.data}
      isError={isError}
      locationId={locationId}
      company={company}
    >
      <LocationDetails />
    </LocationDetailProvider>
  )
}

function LocationDetails() {
  const toast = useToast()
  const navigate = useNavigate()
  const { locationId, location, locationType, company, parent, facilityChildren, isError } =
    useLocationDetailContext()
  const { createOrUpdateLocation, isPending } = useCreateOrUpdateLocation({
    tenantId: company.tenant_id,
    onSuccess: () => {
      toast({
        description: 'Successfully updated location',
      })
      navigate(`/companies/${getEnvironment()}/${company.slug}/locations`)
    },
    onError: () => {
      toast({
        status: 'error',
        description: 'Failed to update location',
      })
    },
  })
  const { data: positions } = usePositions({
    queryParamKey: 'elevator_id',
    queryParamValue: location?.centreId,
  })

  const locationFormSchema = Yup.object().shape(
    {
      sourceId: Yup.string().required('Required'),
      physicalAddressLine1: Yup.string().when([], {
        is: () => locationType === LocationType.facility,
        then: () =>
          Yup.string().required('Address 1 is required when the location is marked as a facility'),
        otherwise: () => Yup.string().nullable(),
      }),
      sourceName: Yup.string().when(['internalName', 'publicName'], {
        is: (internalName: string, publicName: string) => !internalName && !publicName,
        then: () => Yup.string().required('A name is required'),
        otherwise: () => Yup.string().nullable(),
      }),
      internalName: Yup.string().when(['sourceName', 'publicName'], {
        is: (sourceName: string, publicName: string) => !sourceName && !publicName,
        then: () => Yup.string().required('A name is required'),
        otherwise: () => Yup.string().nullable(),
      }),
      publicName: Yup.string().when(['internalName', 'sourceName'], {
        is: (internalName: string, sourceName: string) => !internalName && !sourceName,
        then: () => Yup.string().required('A name is required'),
        otherwise: () => Yup.string().nullable(),
      }),
    },
    [
      ['internalName', 'publicName'],
      ['sourceName', 'publicName'],
      ['internalName', 'sourceName'],
    ]
  )

  function formatFacilityChildren(location: TenantLocation, parentId?: string) {
    return {
      data: {
        ...location,
        companyId: company.id,
        parentId,
      },
      type: LocationType.location,
    }
  }

  function makeRequestData(formData: TenantLocation): CreateOrUpdateLocationRequest[] {
    Object.keys(formData).forEach(
      (key) => (formData[key] = formData[key] === '' ? null : formData[key])
    )

    let requestData = []
    requestData.push({
      data: {
        ...formData,
        integrationId: `${formData.datasourceId}-${formData.sourceId}`,
        parentId: parent?.id,
      },
      type: locationType,
    })

    location?.children?.forEach((l) => {
      if (!facilityChildren?.some((fc) => fc.id === l.id)) {
        requestData.push(formatFacilityChildren(l, null))
      }
    })
    facilityChildren?.forEach((fc) => requestData.push(formatFacilityChildren(fc, locationId)))

    return requestData
  }

  async function save(data: TenantLocation) {
    await createOrUpdateLocation(makeRequestData(data))
  }

  return (
    <Formik
      initialValues={{
        id: locationId,
        companyId: company.id,
        datasourceId: company.datasource_id,
        sourceId: location?.sourceId,
        sourceName: location?.sourceName,
        internalName: location?.internalName,
        publicName: location?.publicName,
        parentId: location?.parentId,
        physicalAddressLine1: location?.physicalAddressLine1,
        physicalAddressLine2: location?.physicalAddressLine2,
        physicalLocality: location?.physicalLocality,
        physicalAdministrativeArea: location?.physicalAdministrativeArea,
        physicalPostalCode: location?.physicalPostalCode,
        physicalCountryCode: location?.physicalCountryCode,
        latitude: location?.latitude,
        longitude: location?.longitude,
        integrationId: location?.integrationId,
        businessType: location?.businessType,
        businessEmail: location?.businessEmail,
        businessWebsite: location?.businessWebsite,
        businessFax: location?.businessFax,
        businessPhone: location?.businessPhone,
        pushNotificationsHiddenAt: location?.pushNotificationsHiddenAt,
        cashBidsHiddenAt: location?.cashBidsHiddenAt,
        hidden: location?.hidden ?? false,
        revision: location?.revision,
      }}
      validationSchema={locationFormSchema}
      onSubmit={save}
    >
      {(formikProps: FormikProps<TenantLocation>) => (
        <ConfigCard
          header={
            <Box mb={6}>
              <Button
                as={Link}
                to={`/companies/${getEnvironment()}/${company.slug}/locations`}
                width="76px"
                size="sm"
                mr={2}
                colorScheme="secondary"
                isDisabled={isPending}
                isLoading={false}
              >
                CANCEL
              </Button>
              <Button
                width="76px"
                size="sm"
                mr={2}
                colorScheme="primary"
                isDisabled={!hasPermission('company_locations_edit') || isPending || isError}
                isLoading={isPending}
                onClick={() => {
                  formikProps.submitForm()
                }}
              >
                {locationId ? 'Save' : 'Add'}
              </Button>
            </Box>
          }
        >
          <Form>
            <FormRow>
              <TextField name="id" label="ID" readOnly />
              <TextField name="sourceId" label="Remote ID" readOnly={!!locationId} />
            </FormRow>
            <FormRow>
              <TextField name="sourceName" label="Source Name" readOnly />
              <TextField name="internalName" label="Internal Name" />
              <TextField name="publicName" label="Public Name" />
              <Box flex={1} />
            </FormRow>
            <FormRow>
              <TextField name="physicalAddressLine1" label="Address Line 1" />
              <TextField name="physicalAddressLine2" label="Address Line 2" />
            </FormRow>
            <FormRow>
              <TextField name="physicalLocality" label="City" />
              <TextField name="physicalAdministrativeArea" label="State" />
              <TextField name="physicalPostalCode" label="ZIP Code" />
              <SelectField
                name="physicalCountryCode"
                label="Country"
                options={[
                  { label: 'USA', value: 'USA' },
                  { label: 'CAN', value: 'CAN' },
                ]}
              />
            </FormRow>
            <FormRow>
              <TextField name="businessPhone" label="Phone" />
              <TextField name="businessFax" label="Fax" />
              <TextField name="businessEmail" label="Email" />
              <TextField name="businessWebsite" label="Website" />
            </FormRow>
            <FormRow>
              <SwitchField name="cashBidsHiddenAt" label="Hide in Cash Bids" timed />
              <SwitchField
                name="pushNotificationsHiddenAt"
                label="Hide in Push Notifications"
                timed
              />
              <SwitchField name="hidden" label="Hidden" />
              <MultiSelectField
                name="businessType"
                label="Business Type"
                options={[
                  { label: 'Agronomy', value: 'Agronomy' },
                  { label: 'Grain Delivery', value: 'Grain Delivery' },
                  { label: 'Feed', value: 'Feed' },
                  { label: 'Office', value: 'Office' },
                  { label: 'Plant', value: 'Plant' },
                  { label: 'Seed', value: 'Seed' },
                ]}
              />
            </FormRow>
            {locationId && <FacilityOptions />}
            {locationId && positions && (
              <LocationPositions
                locationRemoteId={location.sourceId}
                positions={positions}
                company={company}
                isEditing={true}
              />
            )}
          </Form>
        </ConfigCard>
      )}
    </Formik>
  )
}
